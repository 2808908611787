import axios from 'axios';

let prefix = "/recycling-history"

const RecyclingHistoryApi = {
	
	getRecyclingHistoryList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getRecyclingHistoryById(id, payload){
		return axios.get( prefix + "/get/" + id, { params: payload })
	},
	createRecyclingHistory(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateRecyclingHistory(payload){
		return axios.post( prefix + "/update", payload)
	},
	updateRecyclingHistoryStatus(payload){
		return axios.post( prefix + "/update-status", payload)
	},
	deleteRecyclingHistory(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},

}

export default RecyclingHistoryApi;