import axios from 'axios';

let prefix = "/recyclable-category"

const RecyclabeCategoryApi = {
	
	getRecyclabeCategoryList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getRecyclabeCategory(payload){
		return axios.get( prefix + "/get/", {params: payload})
	},
	createRecyclabeCategory(payload) {
		return axios.post(prefix + "/create", payload)
	},
	updateRecyclabeCategory(payload){
		return axios.post( prefix + "/update", payload)
	},
	archiveRecyclabeCategory(payload) {
		return axios.delete(prefix + "/delete", { params: payload })
	},
}

export default RecyclabeCategoryApi;